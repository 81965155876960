export default function Card(){
return(
    <div className="bigcard">
        <p>
        My name is Malik Nijem, and I’m a dedicated, 
        self-taught programmer with a genuine passion for learning 
        and solving problems. Over the years, I’ve worked with a range 
        of technology stacks, adapting to whatever a project needs and 
        always embracing new challenges. While Node.js is at the heart 
        of my skill set, I enjoy the process of exploring and mastering 
        new tools to meet project demands. I believe in making the web 
        more accessible and open to everyone, and I’m interested in 
        opportunities that allow me to grow while contributing meaningfully 
        to impactful projects.
        </p>
    </div>
)}