// src/hooks/useLenis.js
import { useEffect } from 'react';
import Lenis from '@studio-freight/lenis';

const useLenis = () => {
    useEffect(() => {
        // Initialize Lenis with custom options
        const lenis = new Lenis({
            duration: 0.8,
            easing: (t) => t * (2 - t),
            smooth: true,
            direction: 'vertical',
            smoothTouch: true,
        });

        // Function to handle anchor clicks with Lenis scrolling
        const handleAnchorClick = (event) => {
            const href = event.currentTarget.getAttribute('href');
            if (href && href.startsWith('#')) {
                event.preventDefault();
                const target = document.querySelector(href);
                if (target) {
                    lenis.scrollTo(target);
                }
            }
        };

        // Attach event listeners to all anchor links
        const anchorLinks = document.querySelectorAll('a[href^="#"]');
        anchorLinks.forEach((link) => {
            link.addEventListener('click', handleAnchorClick);
        });

        // Animation loop for Lenis
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);

        // Cleanup on unmount
        return () => {
            lenis.destroy();
            anchorLinks.forEach((link) => {
                link.removeEventListener('click', handleAnchorClick);
            });
        };
    }, []);
};

export default useLenis;
