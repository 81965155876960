import { useState } from "react";
import emailjs from "emailjs-com";
import AnimatedCursor from "react-animated-cursor";
export default function Contact() {
  const [email, setEmail] = useState('');
  const [message, setEmailContent] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [name, setName] = useState('');



  const handleSubmit = (e) => {

    e.preventDefault();

    // Input validation
    if (!message && !email && !name) {
      setError(<p className="required">Fill in your name, email and message</p>);
    } else if (!message) {
      setError(<p className="required">Message is empty. Please type a message</p>);
    } else if (!email) {
      setError(<p className="required">Email is empty. Please type your email address</p>);
    } else if (!name) {
      setError(<p className="required">Name is empty. Please type your name</p>);
    } else {
      setError('');
      setSuccessMessage('Sending...');

      // Define email parameters
      const templateParams = {
        to_name: "Malik",
        from_name: name,
        from_email: email,
        message: message,
      };

      // Send email using EmailJS
      emailjs
        .send('killmewithafork', 'template_0v3iix6', templateParams, 'MNKfwRWg1uL3Ujyz6')
        .then(
          (response) => {
            console.log('Email sent successfully:', response.status, response.text);
            setEmail(''); // Clear email input
            setEmailContent(''); // Clear message input
            setName('')
            setSuccessMessage('Message sent successfully!');

          },
          (error) => {
            console.error('Failed to send email:', error);
            setError(<p className="required">Failed to send message. Please try again later.</p>);
          }
        );
    }
  };

  return (
    <> 
     <AnimatedCursor 
      innerSize={20}
      outerSize={40}
      color='255,255,255'
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={2}
      clickables={[
      'a',
      'input[type="text"]',
      'input[type="email"]',
      'input[type="number"]',
      'input[type="submit"]',
      'input[type="image"]',
      'label[for]',
      'select',
      'textarea',
      'button',
      '.link',
      {
        target: '.custom',
        options: {
          innerSize: 12,
          outerSize: 12,
          color: '255, 255, 255',
          outerAlpha: 0.3,
          innerScale: 0.7,
          outerScale: 5
        }
      }
    ]} />
      <div className="contactdiv">
        <form className="contactform" onSubmit={handleSubmit}>
        <label>Your Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <label>Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>Message</label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setEmailContent(e.target.value)}
          />
          {error}
          {successMessage && <p className="success">{successMessage}</p>}
          <button type="submit">Send!</button>
        </form>
      </div>
    </>
  );
}
