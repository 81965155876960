import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    return (
        <div className="header">
            <ul className="headerList">
                <li>
                    {isHomePage ? (
                        <a href="#section3" className="linkStyles">ABOUT</a>
                    ) : (
                        <Link to="/" className="linkStyles">ABOUT</Link>
                    )}
                </li>
                <li>
                    {isHomePage ? (
                        <a href="#section2" className="linkStyles">SKILLS</a>
                    ) : (
                        <Link to="/" className="linkStyles">SKILLS</Link>
                    )}
                </li>
                <li>
                    {isHomePage ? (
                        <a href="#section4" className="linkStyles">EXPERIENCE</a>
                    ) : (
                        <Link to="/" className="linkStyles">EXPERIENCE</Link>
                    )}
                </li>
                <li className="right">
                    <Link className="linkStyles" to="/contact-me">CONTACT ME</Link>
                </li>
            </ul>
        </div>
    );
}
              
 
 
 
 