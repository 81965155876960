import Card from '../components/Card.js'
import MainContent from '../components/MainContent.js'
import SmallCard from "../components/SmallCard";
import js from '../imgs/js.svg';
import html from '../imgs/html.svg';
import css from '../imgs/css.svg';
import mysql from '../imgs/sql.png';
import git from '../imgs/git.svg';
import python from '../imgs/python.png';
import node from '../imgs/node.png'
import {ReactLenis} from 'lenis/react'
import ProjectCard from '../components/ProjectCard.js';
import AutoPlay from '../components/Carousel.js';
import {Routes, Route, useNavigate, Navigate} from 'react-router-dom';
import useLenis from '../components/UseLenis.js';
import AnimatedCursor from "react-animated-cursor"
import {Container} from "react-bootstrap"

import { CSSTransition, SwitchTransition } from "react-transition-group"

export default function Home(){
    const navigate = useNavigate();
        function goToContactPage(){
        // alert('go to contact me page')
        navigate('/contact-me');
    }
    const logo = require('../imgs/cropped-colored-stanley.png');
    useLenis();
    
return (
    <><AnimatedCursor 
    innerSize={15}
    outerSize={40}
    color='255,255,255'
    outerAlpha={0.2}
    innerScale={0.7}
    outerScale={2}

    // trailingSpeed={3}
    // innerStyle={{
    //     backgroundColor: '255,255,255'
    //   }}
    //   outerStyle={{
    //     border: '10px solid var(--cursor-color)'
    //   }}
    clickables={[
      'a',
      'input[type="text"]',
      'input[type="email"]',
      'input[type="number"]',
      'input[type="submit"]',
      'input[type="image"]',
      'label[for]',
      'select',
      'textarea',
      'button',
      '.link',
      {
        target: '.custom',
        options: {
          innerSize: 12,
          outerSize:12,
          color: '193, 11, 111',
          outerAlpha: 0.3,
          innerScale: 0.7,
          outerScale: 5
        }
      }
    ]} />
    <Container className="container">
       <SwitchTransition>
       <CSSTransition>
<ReactLenis root>
    { 
     
        <div className="App">
            <div className="section1" id="section1">
                <MainContent  stan= {logo} /> 
            </div>

            <div className="section2" id="section2">
                <div className="skillscarosel">
                    <AutoPlay>
                        <SmallCard skillimg={js}/>
                        <SmallCard skillimg={html}/>         
                        <SmallCard skillimg={css}/>
                        <SmallCard skillimg={mysql}/>
                        <SmallCard skillimg={git}/>
                        <SmallCard skillimg={python}/>
                        <SmallCard skillimg={node}/>
                    </AutoPlay>
                </div>
            </div>

            <div className="section3" id="section3">
                <Card />
            </div>

            <div class="section4" id="section4" >
                <ul id="cards">
                    <li class="card" id="card1">
                            <ProjectCard texttitle="Support Agent" textbody="Starting my career as a Support Agent, I faced the challenge of understanding complex customer needs quickly while delivering efficient solutions. Through determination and a commitment to problem-solving, I mastered communication techniques and conflict resolution. This foundational role sharpened my ability to manage high-pressure situations, paving the way for my advancement."/>
                    </li>
                    <li class="card" id="card2">
                            <ProjectCard texttitle="Tech Support" textbody="In Tech Support, I tackled the intricacies of diagnosing and resolving technical issues in real-time, which required swift adaptability and technical knowledge. The role refined my troubleshooting abilities and deepened my understanding of IT systems. My persistence and analytical skills here were critical in preparing me for more technical responsibilities."/>
                    </li>
                    <li class="card" id="card3">
                        <ProjectCard texttitle="IT Developer" textbody="As an IT Developer, I embraced the challenges of coding and systems design, working tirelessly to create solutions that balanced efficiency with user-friendliness. Each problem I solved further honed my development skills, teaching me how to think critically and innovate. My success in delivering reliable applications laid the groundwork for future leadership."/>
                    </li>
                    <li class="card" id="card4">
                        <ProjectCard texttitle="Head of Development" textbody="Now as Head of Development, I lead projects that define our technical direction, facing the complex challenge of uniting teams and technology under strategic goals. Through continuous learning and a drive to inspire, I’ve cultivated an environment focused on growth and excellence, leading us to create impactful solutions and foster team innovation."/>
                    </li>
                </ul>
            </div>

            <div className="section5">
                <div onClick={goToContactPage} className='getInTouch'>
                    <p>GET IN TOUCH</p>
                </div>
            </div>
                    
        </div>                     
    }
</ReactLenis>
</CSSTransition>
</SwitchTransition>
</Container>
       
</>
)}
  
 
          