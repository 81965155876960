// import {BrowserRouter, Routes, Route, Layout} from 'react-router-dom'
import Header from './Header.js'
import {createBrowserRouter, RouterProvider,Outlet} from 'react-router-dom'
import Home from '../Pages/Home.js'
import Contact from '../Pages/Contact.js'


export default function Router(){
    const Layout =() => {
        return (
            <>
                <Header />
                <Outlet />
            </>
        )
    }
    const BrowserRoutes = createBrowserRouter([
        {   
            path: "/",
            element:<Layout />,
            children: [       
                {
                    path: "/",
                    element: <Home /> 
                },
                {
                    path: "/contact-me",
                    element: <Contact />
                } 
            ]
        }])
return(
<RouterProvider router={BrowserRoutes} />
)}